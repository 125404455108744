@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: GothamBook;
  src: url("./fonts/GothamBook.ttf");
}

body {
  font-family: "GothamBook", sans-serif;
  background-color: #f6f6f6;
}
